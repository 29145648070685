/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, State } from 'vuex-class'
import {Card} from '@/components/pages/balance-replenishment-by-card/balance-replenishment-by-card.interface';
import { PaymentState } from '@/store/payments/types'

const namespace: string = 'payments';

export default class BalanceReplenishmentByCardPage extends Vue {
	@State('payments') payments: PaymentState | undefined;
	@Action('fetchCardData', {namespace}) fetchCardData: any;

	credentials: Card = {
		cardNumber: '',
		expiryDate: '',
		cvv: '',
		acceptOfferTerms: true
	}

	switchWatchPass: boolean = false;
	typeInput: string = 'password';

	toSwitchWatchPassword() {
		this.switchWatchPass = !this.switchWatchPass;
		if(!this.switchWatchPass) {
			return this.typeInput = 'password';
		} else {
			return this.typeInput = 'text';
		}
	}

	onSubmit() {
		this.fetchCardData({
			cardNumber: this.credentials.cardNumber,
			expiryDate: this.credentials.expiryDate,
			cvv: this.credentials.cvv
		})
		this.credentials.cardNumber = '';
		this.credentials.expiryDate = '';
		this.credentials.cvv = '';
		if(this.credentials.acceptOfferTerms) {
			this.$router.push({name: 'bill-formed'}).then(r => r);
		}
	}
}
